import React, { Component } from "react";
import Select from "react-select";
import Card from "./../common/card";
import DateTime from "../../utils/datetime";
import Pagination from "./../common/pagination";
import { InertiaLink } from "@inertiajs/inertia-react";

const dateTime = new DateTime();

class WebinarTable extends Component {
  constructor(props) {
    super(props);
    this.state = { instance: null };
  }

  handleFilter(event) {
    event.preventDefault();

    const postData = {
      instance: this.state.instance,
      from_date: $("#from_date").val(),
      to_date: $("#to_date").val(),
    };
    window.location.href = route("webinar-report", postData);

  }

  exportTasks(event) {
    event.preventDefault();

    const postData = {
      instance: this.state.instance,
      from_date: $("#from_date").val(),
      to_date: $("#to_date").val(),
      download: true
    };
    window.location.href = route('download-webinar-report', postData);
  }

  render() {
    const { data, instances, links } = this.props;
    return (
      <div>
        <Card title="Participant details" insideBody={false}>
          <div className="p-4">
            <form onSubmit={event => this.handleFilter(event)} className="row">
              <div className="col-sm-3">
                <Select
                  placeholder="Select instance"
                  options={instances}
                  name="instance"
                  onChange={newValue =>
                    this.setState({ instance: newValue.value })
                  }
                />
              </div>
              <div className="col-sm-3">
                <input
                  type="date"
                  className="form-control"
                  placeholder="Select start date"
                  name="from_date"
                  id="from_date"
                />
              </div>
              <div className="col-sm-3">
                <input
                  type="date"
                  className="form-control"
                  placeholder="Select end date"
                  name="to_date"
                  id="to_date"
                />
              </div>
              <div className="col-sm-3">
                <button className="btn btn-primary mr-3">Filter</button>
                <InertiaLink href={route("webinar-report")}>Reset</InertiaLink>

                <span id="export" className="btn btn-success btn-sm" onClick={event => this.exportTasks(event)}>Export</span>

              </div>
            </form>

          </div>
          <table className="table table-striped table-hover mb-3">
            <thead>
              <tr>
                <th>User ID</th>
                <th>Event</th>
                <th>Instance</th>
                <th>Duration</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {row.user_id
                        ? row.user_id
                        : row.user_id}
                    </td>
                    <td>{row.event_title}</td>
                    <td>{row.instance}</td>
                    <td>{row.computed_time}</td>
                    <td>
                      {dateTime.getUtcToLocalTime(
                        row.webinar_date,
                        "MM/DD/YYYY HH:mm:ss"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
        <div className="mt-3">
          <Pagination links={links} />
        </div>
      </div>
    );
  }
}

export default WebinarTable;