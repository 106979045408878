import React, { Component } from "react";
import { InertiaLink } from "@inertiajs/inertia-react";

class Menu extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <InertiaLink className="navbar-brand" href={route("home")}>
          Trualta Manager
        </InertiaLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <InertiaLink className="nav-link" href={route("home")}>
                Home <span className="sr-only">(current)</span>
              </InertiaLink>
            </li>
           
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Webinar
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href={route("participant-report")}>
                  Participant Count
                </a>
                <div className="dropdown-divider" />
                <a className="dropdown-item" href={route("webinar-report")}>
                  Participant Report
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Menu;
