import React, { Component } from "react";

class Card extends Component {
  render() {
    const { title, children, insideBody } = this.props;

    return (
      <div className="card">
        <div className="card-header">{title}</div>
        {insideBody ? (
          <div className="card-body">{children}</div>
        ) : (
          <div>{children}</div>
        )}
      </div>
    );
  }
}

export default Card;
