import React, { Component } from "react";
import Select from "react-select";
import Card from "./../common/card";
import DateTime from "../../utils/datetime";
import Pagination from "./../common/pagination";
import { InertiaLink } from "@inertiajs/inertia-react";
import { Inertia } from "@inertiajs/inertia";

const dateTime = new DateTime();

class WebhookTable extends Component {
  constructor(props) {
    super(props);
    this.state = { instance: null };
  }

  handleFilter(event) {
    event.preventDefault();

    const postData = {
      instance: this.state.instance
    };
    Inertia.visit(route("home"), {data: postData});
  }

  render() {
    const { data, types, links } = this.props;
    return (
      <div>
        <Card title="Email delivery status" insideBody={false}>
          <div className="p-4">
            <form onSubmit={event => this.handleFilter(event)} className="row">
              <div className="col-sm-3">
                <Select
                  placeholder="Select instance"
                  options={types}
                  name="instance"
                  onChange={newValue =>
                    this.setState({ instance: newValue.value })
                  }
                />
              </div>
              <div className="col-sm-3">
                <input
                  type="date"
                  className="form-control"
                  placeholder="Select start date"
                />
              </div>
              <div className="col-sm-3">
                <input
                  type="date"
                  className="form-control"
                  placeholder="Select end date"
                />
              </div>
              <div className="col-sm-3">
                <button className="btn btn-primary mr-3">Filter</button>
                <InertiaLink href={route("home")}>Reset</InertiaLink>
              </div>
            </form>
          </div>
          <table className="table table-striped table-hover mb-3">
            <thead>
              <tr>
                <th>Name</th>
                <th>Subject</th>
                <th>Instance</th>
                <th>Delivered</th>
                <th>Opened</th>
                <th>Clicked</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {row.trualta_username
                        ? row.trualta_username
                        : row.trualta_user_id}
                    </td>
                    <td>{row.subject}</td>
                    <td>{row.from_instance}</td>
                    <td>
                      {dateTime.getUtcToLocalTime(
                        row.delivered_at,
                        "MM/DD/YYYY HH:mm:ss"
                      )}
                    </td>
                    <td>
                      {dateTime.getUtcToLocalTime(
                        row.opened_at,
                        "MM/DD/YYYY HH:mm:ss"
                      )}
                    </td>
                    <td>
                      {dateTime.getUtcToLocalTime(
                        row.clicked_at,
                        "MM/DD/YYYY HH:mm:ss"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
        <div className="mt-3">
          <Pagination links={links} />
        </div>
      </div>
    );
  }
}

export default WebhookTable;
