import React, { Component } from "react";
import Layout from "../../components/common/layout";
import { Helmet } from "react-helmet";

import WebhookTable from "../../components/webhook-table";

class HomePage extends Component {
  render() {
    const { data, links, total } = this.props.data;
    const { notificationTypes } = this.props;
    return (
      <Layout>
        <Helmet>
          <title>My dashboard</title>
        </Helmet>
        <WebhookTable
          data={data}
          links={links}
          types={notificationTypes}
          total={total}
        />
      </Layout>
    );
  }
}

export default HomePage;
