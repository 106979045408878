import React, { Component } from "react";
import Layout from "../../components/common/layout";
import { Helmet } from "react-helmet";
import WebinarTable from "../../components/webhook-table/webinar-table";

class WebinarReportPage extends Component {
  render() {
    const { data, links, total } = this.props.data;
    const { instances } = this.props;
    return (
      <Layout>
        <Helmet>
          <title>Webinar Report</title>
        </Helmet>
        <WebinarTable
          data={data}
          links={links}
          instances={instances}
          total={total}
        />
       
      </Layout>
    );
  }
}

export default WebinarReportPage;
