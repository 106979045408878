import React, { Component } from "react";
import { InertiaLink } from "@inertiajs/inertia-react";

class Pagination extends Component {
  render() {
    const { links } = this.props;
    return (
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          {links.map((link, index) => {
            return (
              <li
                className={`page-item ${link.active && "active"} ${link.url ===
                  null && "disabled"}`}
                key={index}
              >
                <InertiaLink className="page-link" href={link.url}>
                  {link.label}
                </InertiaLink>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

export default Pagination;
