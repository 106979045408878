import React, { Component } from "react";
import Layout from "../../components/common/layout";
import { Helmet } from "react-helmet";
import WebinarParticipantTable from "../../components/webhook-table/webinar-participant-table";

class WebinarParticipantReportPage extends Component {
  render() {
    const { data, links, total } = this.props.data;
    const { instances } = this.props;
    return (
      <Layout>
        <Helmet>
          <title>Webinar participant count report</title>
        </Helmet>
        <WebinarParticipantTable
          data={data}
          links={links}
          instances={instances}
          total={total}
        />
       
      </Layout>
    );
  }
}

export default WebinarParticipantReportPage;
