import moment from "moment";

class DateTime {
  getUtcToLocalTime(time, format = "HH:mm:ss") {
    if (!time) {
      return "";
    }
    let localTime = moment.utc(time).toDate();
    localTime = moment(localTime).format(format);
    return localTime;
  }
}

export default DateTime;
