import React, { Component } from "react";
import Menu from "../menu";

class Layout extends Component {
  render() {
    return (
      <div>
        <div className="mb-5">
          <Menu />
        </div>
        <div className="container">{this.props.children}</div>
      </div>
    );
  }
}

export default Layout;
